<template>
  <div class="home">
    <v-main class="mx-4 mt-4">
      <router-view :key="$route.fullPath" />
    </v-main>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
.home {
  background-color: rgba(57, 74, 88, 0.2);
  font-family: RussianRail;
  height: 100%;
}
</style>
